<template>
  <div>
    <b-modal no-close-on-backdrop :id="id" size="xl" :title="`${this.$route.params.propertyId ? 'View/Edit' : 'Create'} Property`" @hidden="closeModal" body-class="py-0">
      <b-row>
        <b-col cols="12" v-if="!loading && error">
          <b-alert variant="danger">{{ error }}</b-alert>
        </b-col>
        <b-col class="py-4 text-center" cols="12" v-if="loading">
          <b-spinner variant="primary"></b-spinner>
        </b-col>
      </b-row>
      <b-row v-if="!loading && !error">
        <b-col class="modal-body-col py-3" cols="8">
          <b-row>
            <b-col cols="12">
              <b-form-group label="Owner" :invalid-feedback="join('; ', validationErrors.client_id)" :state="!validationErrors.client_id">
                <user-search class="mb-2" type="client" v-model="editedProperty.client_id" />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <b-form-group label="Name" :invalid-feedback="join('; ', validationErrors.name)" :state="!validationErrors.name">
                <b-form-input type="text" v-model="editedProperty.name" />
              </b-form-group>
              <b-form-group label="Website Property ID" :invalid-feedback="join('; ', validationErrors.website_property_id)" :state="!validationErrors.website_property_id">
                <b-form-input type="text" v-model="editedProperty.website_property_id" />
              </b-form-group>
              <b-form-group label="Description" :invalid-feedback="join('; ', validationErrors.desc)" :state="!validationErrors.desc">
                <b-form-textarea rows="2" v-model="editedProperty.desc" />
              </b-form-group>
              <b-form-group label="Long Description" :invalid-feedback="join('; ', validationErrors.long_desc)" :state="!validationErrors.long_desc">
                <b-form-textarea rows="4" v-model="editedProperty.long_desc" />
              </b-form-group>
              <b-form-group label="Payee Details" :invalid-feedback="join('; ', validationErrors.payee_details)" :state="validationErrors.payee_details">
                <b-form-input type="text" v-model="editedProperty.payee_details" />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" lg="4">
              <b-form-group label="Bedrooms" :invalid-feedback="join('; ', validationErrors.bedrooms)" :state="!validationErrors.bedrooms">
                <b-form-input type="number" min="1" v-model.number="editedProperty.bedrooms" />
              </b-form-group>
            </b-col>
            <b-col cols="12" lg="4">
              <b-form-group label="Bathrooms" :invalid-feedback="join('; ', validationErrors.bathrooms)" :state="!validationErrors.bathrooms">
                <b-form-input type="number" min="1" v-model.number="editedProperty.bathrooms" />
              </b-form-group>
            </b-col>
            <b-col cols="12" lg="4">
              <b-form-group label="Max Guests" :invalid-feedback="join('; ', validationErrors.max_guests)" :state="!validationErrors.max_guests">
                <b-form-input type="number" min="1" v-model.number="editedProperty.max_guests" />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" lg="6">
              <b-form-group label="Min Nights" :invalid-feedback="join('; ', validationErrors.min_nights)" :state="!validationErrors.min_nights">
                <b-form-input type="number" min="1" v-model.number="editedProperty.min_nights" />
              </b-form-group>
            </b-col>
            <b-col cols="12" lg="6">
              <b-form-group label="Max Nights" :invalid-feedback="join('; ', validationErrors.max_nights)" :state="!validationErrors.max_nights">
                <b-form-input type="number" min="1" v-model.number="editedProperty.max_nights" />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" lg="4">
              <b-form-group label="Statement Prefix" :invalid-feedback="join('; ', validationErrors.statement_prefix)" :state="!validationErrors.statement_prefix">
                <b-form-input type="text" v-model="editedProperty.statement_prefix" />
              </b-form-group>
            </b-col>
            <b-col cols="12" lg="4">
              <b-form-group label="Current Statement No." :invalid-feedback="join('; ', validationErrors.statement_from)" :state="!validationErrors.statement_from">
                <b-form-input type="number" min="1" v-model.number="editedProperty.statement_from" />
              </b-form-group>
            </b-col>
            <b-col cols="12" lg="4">
              <b-form-group label="Deposit" :invalid-feedback="join('; ', validationErrors.deposit)" :state="!validationErrors.deposit">
                <b-input-group prepend="£">
                  <b-form-input type="number" step="0.01" v-model="editedProperty.deposit" />
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" lg="3">
              <b-form-group label="Cleaning Fee" :invalid-feedback="join('; ', validationErrors.cleaning_fee)" :state="!validationErrors.cleaning_fee">
                <b-input-group prepend="£">
                  <b-form-input type="number" step="0.01" v-model="editedProperty.cleaning_fee" />
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col cols="12" lg="3">
              <b-form-group label="Owner Cleaning Fee" :invalid-feedback="join('; ', validationErrors.owner_cleaning_fee)" :state="!validationErrors.owner_cleaning_fee">
                <b-input-group prepend="£">
                  <b-form-input type="number" step="0.01" v-model="editedProperty.owner_cleaning_fee" />
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col cols="12" lg="3">
              <b-form-group label="Minimum Fee" :invalid-feedback="join('; ', validationErrors.mgmt_fee)" :state="!validationErrors.mgmt_fee">
                <b-input-group prepend="£">
                  <b-form-input type="number" step="0.01" v-model="editedProperty.mgmt_fee" />
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col cols="12" lg="3">
              <b-form-group label="Management %" :invalid-feedback="join('; ', validationErrors.mgmt_perc)" :state="!validationErrors.mgmt_perc">
                <b-input-group append="%">
                  <b-form-input type="number" min="1" max="99" v-model.number="editedProperty.mgmt_perc" />
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="border-top mt-2 pt-4">
            <b-col cols="12">
              <p class="h5 mb-4 font-weight-bold">Address Information</p>
              <b-form-group label="Address Line 1" :invalid-feedback="join('; ', validationErrors['address.address_line_1'])" :state="!(validationErrors['address.address_line_1'])">
                <b-form-input type="text" v-model="editedProperty.address.address_line_1" />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group label="Address Line 2" :invalid-feedback="join('; ', validationErrors['address.address_line_2'])" :state="!(validationErrors['address.address_line_2'])">
                <b-form-input type="text" v-model="editedProperty.address.address_line_2" />
              </b-form-group>
            </b-col>
            <b-col cols="4">
              <b-form-group label="City" :invalid-feedback="join('; ', validationErrors['address.city'])" :state="!(validationErrors['address.city'])">
                <b-form-input type="text" v-model="editedProperty.address.city" />
              </b-form-group>
            </b-col>
            <b-col cols="4">
              <b-form-group label="County" :invalid-feedback="join('; ', validationErrors['address.county'])" :state="!(validationErrors['address.county'])">
                <b-form-input type="text" v-model="editedProperty.address.county" />
              </b-form-group>
            </b-col>
            <b-col cols="4">
              <b-form-group label="Postcode" :invalid-feedback="join('; ', validationErrors['address.postcode'])" :state="!(validationErrors['address.postcode'])">
                <b-form-input type="text" v-model="editedProperty.address.postcode" />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="border-top mt-2 pt-4">
            <b-col cols="12">
              <p class="h5 mb-4 font-weight-bold">Access Information</p>
            </b-col>
            <b-col cols="6">
              <b-form-group label="Key Hook" :invalid-feedback="join('; ', validationErrors['key.key_hook'])" :state="!(validationErrors['key.key_hook'])">
                <b-form-input type="text" v-model="editedProperty.key.key_hook" />
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label="Keybox Code" :invalid-feedback="join('; ', validationErrors['key.keybox_code'])" :state="!(validationErrors['key.keybox_code'])">
                <b-form-input type="text" v-model="editedProperty.key.keybox_code" />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="border-top my-2 pt-4">
            <b-col cols="10">
              <p class="h5 font-weight-bold">Property Rates</p>
            </b-col>
            <b-col class="d-flex" cols="2">
              <b-button @click.prevent="editedProperty.rates.push({
                start_date: `${new Date().getFullYear()}-01-01`,
                end_date: `${new Date().getFullYear()}-12-31`,
                amount: 0
              })" class="flex-fill" variant="primary"><fa-icon class="mr-2" icon="plus" />Add</b-button>
            </b-col>
          </b-row>
          <b-row class="mb-2" :key="`rate_${ind}`" v-for="(rate, ind) in editedProperty.rates">
            <b-col cols="12">
              <b-card>
                <b-row>
                  <b-col cols="12" md="6">
                    <b-form-group label="Start Date">
                      <b-form-datepicker hide-header :date-format-options="{ year: undefined, month: 'long', date: 'numeric'}" :min="`${new Date().getFullYear()}-01-01`" :max="`${new Date().getFullYear()}-12-31`" v-model="rate.start_date" />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="6">
                    <b-form-group label="End Date">
                      <b-form-datepicker hide-header :date-format-options="{ year: undefined, month: 'long', date: 'numeric'}" :min="`${new Date().getFullYear()}-01-01`" :max="`${new Date().getFullYear()}-12-31`" v-model="rate.end_date" />
                    </b-form-group>
                  </b-col>
                  <b-col cols="10">
                    <b-form-group label="Amount">
                      <b-input-group prepend="£">
                        <b-form-input type="number" step="0.01" v-model="rate.amount" />
                      </b-input-group>
                    </b-form-group>
                  </b-col>
                  <b-col class="d-flex flex-row align-items-end" cols="2">
                    <b-button block class="mb-3" @click.prevent="() => editedProperty.rates.splice(ind, 1)" variant="danger"><fa-icon icon="trash" /></b-button>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
          </b-row>
          <b-row class="border-top my-2 pt-4">
            <b-col cols="10">
              <p class="h5 font-weight-bold">Property Features</p>
            </b-col>
            <b-col class="d-flex" cols="2">
              <b-button @click.prevent="editedProperty.features.push({name: '', value: true})" class="flex-fill" variant="primary"><fa-icon class="mr-2" icon="plus" />Add</b-button>
            </b-col>
          </b-row>
          <b-row class="mb-2" :key="`feat_${ind}`" v-for="(feature, ind) in editedProperty.features">
            <b-col cols="12">
                <b-form-group :invalid-feedback="join('; ', validationErrors[`features.${ind}.name`])" :state="!validationErrors[`features.${ind}.name`]">
                <b-input-group>
                  <template #prepend>
                    <b-input-group-prepend is-text>
                      <b-checkbox class="ml-2 mr-0" v-model="feature.value" />
                    </b-input-group-prepend>
                  </template>
                    <b-form-input type="text" v-model="feature.name" />
                  <template #append>
                    <b-button @click.prevent="() => editedProperty.features.splice(ind, 1)" variant="danger"><fa-icon icon="trash" /></b-button>
                  </template>
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
        <b-col class="border-left bg-light d-flex flex-column justify-items-between modal-body-col py-3" cols="4">
          <b-row>
            <b-col cols="12">
              <p class="h5 font-weight-bold">Uploaded Images</p>
              <b-row>
                <b-col cols="6" :key="`file_${ind}`" v-for="(upload, ind) in editedProperty.files">
                  <b-card body-class="p-1" class="mb-4">
                    <img class="border mr-2 w-100" :src="upload.url" />
                    <b-button block @click="editedProperty.files.splice(ind, 1)" variant="danger"><fa-icon icon="trash" /> Delete</b-button>
                  </b-card>
                </b-col>
              </b-row>
            </b-col>
            <b-col class="border-top border-bottom py-2" cols="12">
              <b-form-file accepts=".gif, .jpg, .png, .heic" :disabled="!$route.params.propertyId" multiple v-model="filesToUpload" />
            </b-col>
            <b-col class="border-top border-bottom mt-3 pt-3" cols="12" v-if="filesToUploadData.length > 0">
              <p class="h5 font-weight-bold">Files to Upload</p>
              <p class="mb-2 text-muted"><small>To upload these files, click "Update" below.</small></p>
              <b-card body-class="p-1" class="mb-2" :key="`ftu_${ind}`" v-for="(file, ind) in filesToUpload">
                <div class="d-flex flex-row">
                  <img class="border mr-2" :src="filesToUploadData[ind]" height="75" width="75" />
                  <div class="align-self-center ml-1">
                    <p class="font-weight-bold mb-0">{{ file.name }}</p>
                    <p class="text-muted mb-1">{{ (new Intl.NumberFormat('en-GB', { maximumFractionDigits: 1 })).format(file.size / 1024) }} KB</p>
                  </div>
                </div>
              </b-card>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <template #modal-footer>
        <b-form-checkbox class="mr-4" v-model="editedProperty.bookable">Bookable</b-form-checkbox>
        <b-form-checkbox class="mr-4" v-model="editedProperty.fully_managed">Fully Managed</b-form-checkbox>
        <b-button @click.prevent="closeModal" variant="light">Close</b-button>
        <b-button @click.prevent="saveProperty" variant="primary">
          <b-spinner class="mx-4" variant="light" small v-if="saving"/>
          <span v-if="!saving">{{ $route.params.propertyId ? 'Update' : 'Create' }}</span>
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import UserSearch from '../../components/UserSearch.vue';
import handleErrors from '../../mixins/handleErrors';

export default {
  components: { UserSearch },
  emits: ['hidden'],
  mixins: [handleErrors],
  data() {
    return {
      validationErrors: {},
      genericError: undefined,
      filesToUpload: [],
      filesToUploadData: [],
      editedProperty: {
        name: '',
        website_property_id: '',
        desc: '',
        client_id: undefined,
        long_desc: '',
        min_guests: 1,
        max_guests: 1,
        min_nights: 1,
        max_nights: 1,
        bathrooms: 1,
        bedrooms: 1,
        bookable: false,
        fully_managed: false,
        address: {
          address_line_1: '',
          address_line_2: '',
          city: '',
          county: '',
          postcode: '',
        },
        features: [
          {
            value: true,
            name: '',
          }
        ],
        key: {
          key_hook: '',
          keybox_code: ''
        },
        rates: [
          {
            amount: 0,
            start_date: `${new Date().getFullYear()}-01-01`,
            end_date: `${new Date().getFullYear()}-12-31`,
          }
        ]
      },
      uploading: false,
      loading: false,
      saving: false,
      error: '',
    };
  },
  props: {
    property: Object,
    id: String,
  },
  async mounted() {
    if (this.$route.params.propertyId && this.property) {
      this.loading = true;

      try {
        const property = await this.fetchSingle(this.$route.params.propertyId);
        this.editedProperty = {
          ...property,
          client_id: property.client.user_id,
          address: {
            ...(property.address || {}),
          },
          features: [
            ...(property.features || []),
          ],
          key: {
            ...(property.key || {}),
          },
        };
        this.loading = false;
      } catch (err) {
        this.error = "Can not load property. Please try again later.";
        this.loading = false;
      }
    }
  },
  methods: {
    ...mapActions('properties', ['fetch', 'fetchSingle', 'updateOrCreate']),
    join(join, arr) {
      if (arr && arr.length) {
        return arr.join(join)
      }

      return ''
    },
    closeModal() {
      this.editedProperty = {
        name: '',
        website_property_id: '',
        desc: '',
        client_id: undefined,
        long_desc: '',
        min_guests: 1,
        max_guests: 1,
        min_nights: 1,
        max_nights: 1,
        bathrooms: 1,
        bedrooms: 1,
        bookable: false,
        fully_managed: false,
        address: {
          address_line_1: '',
          address_line_2: '',
          city: '',
          county: '',
          postcode: '',
        },
        features: [
          {
            value: true,
            name: '',
          }
        ],
        key: {
          key_hook: '',
          keybox_code: ''
        },
        rates: [
          {
            amount: 0,
            start_date: `${new Date().getFullYear()}-01-01`,
            end_date: `${new Date().getFullYear()}-12-31`,
          }
        ]
      };
      this.validationErrors = {};
      this.filesToUpload = [];
      if (this.$route.name !== this.$route.meta.parentName) {
        this.$router.push({ name: this.$route.meta.parentName });
      }
      this.$emit('hidden')
    },
    getDataUri(file) {
      return new Promise(resolve => {
        const fr = new FileReader();
        fr.addEventListener('load', () => resolve(fr.result));
        fr.readAsDataURL(file)
      })
    },
    saveProperty() {
      this.saving = true;
      this.uploadFiles().then(() =>
        this.updateOrCreate(this.editedProperty).then(() => {
          this.saving = false;
          this.closeModal();
          return this.fetch();
        })
      ).catch(err => {
        this.saving = false;
        if (err.response && err.response.status === 422) {
          this.saving = false;
          this.handleValidationErrors(err);
        } else {
          this.saving = false;
          this.genericError = 'There was an error saving just now. Please try again later.';
        }
      });
    },
    async uploadFiles() {
      const formData = new FormData();
      this.filesToUpload.map(file =>
        formData.append('files[]', file, file.name)
      );

      if (this.filesToUpload.length) {
        const response = await window.apiFetch(`/properties/${this.$route.params.propertyId}/upload`, {
          method: 'POST',
          body: formData,
        })
        const uploads = await response.json();
        this.editedProperty.files = [
          ...this.editedProperty.files,
          ...uploads
        ];
      }

      return;
    }
  },
  watch: {
    property(value) {
      this.editedProperty = {
        ...value,
        client_id: value.client?.user_id,
        address: {
          ...(value.address || {})
        },
        features: [
          ...(value.features || []),
        ],
        key: {
          ...(value.key || {})
        }
      };
    },
    async filesToUpload(value) {
      this.filesToUploadData = await Promise.all(value.map(f => {
        return this.getDataUri(f);
      }));
    }
  }
}
</script>

<style>
.modal-body-col {
  height: 75vh;
  overflow: auto;
}
</style>
